import axios, { AxiosResponse } from 'axios';
import { SweetAlert } from '../utilities';
import messages from '../resources/messages';

class Api {
  constructor() {
    axios.defaults.baseURL = 'https://demo-backend.facturalo.pe/api/v1';
    axios.defaults.headers.common['Content-Type'] = 'application/json';
    axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response) {
          
          if (error.response.status === 401) {
            switch (error.response.data.code) {
              case '001':
                SweetAlert.error('Code: ' + error.response.data.code, error.response.data.message);
                break;
              case '002':
                SweetAlert.error('Code: ' + error.response.data.code, error.response.data.message);
                break;
              default:
                SweetAlert.error(messages.TOKEN_EXPIRED_CODE, messages.TOKEN_EXPIRED_MESSAGE);
                break;
            }
          }
          if(error.response.status === 500){
            SweetAlert.error('Error en el servidor: ', 'Mensaje: ' + error.response.data.message);
          }
        } else if (error.request) {
          SweetAlert.warning(messages.NOT_CONECTION_CODE, messages.NOT_CONECTION_MESSAGE);
        }
        return Promise.reject(error);
      },
    );
  }

  private addTokenToRequest(config: any) {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
  }

  // Create a method for making GET requests
  async get<T>(url: string): Promise<T> {
    try {
      const config = {
        headers: {},
      };
      this.addTokenToRequest(config);
      const response: AxiosResponse<T> = await axios.get(url, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Create a method for making POST requests
  async post<T>(url: string, params: any): Promise<T> {
    try {
      const config = {
        headers: {},
      };
      this.addTokenToRequest(config);
      const response: AxiosResponse<T> = await axios.post(url, params, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Create a method for making PATCH requests
  async update<T>(url: string, params: any): Promise<T> {
    try {
      const config = {
        headers: {},
      };
      this.addTokenToRequest(config);
      const response: AxiosResponse<T> = await axios.patch(url, params, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Create a method for making DELETE requests
  async destroy<T>(url: string): Promise<T> {
    try {
      const config = {
        headers: {},
      };
      this.addTokenToRequest(config);
      const response: AxiosResponse<T> = await axios.delete(url, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

  // Create a method for making POST requests with file data
  async postWithFile<T>(url: string, formData: FormData): Promise<T> {
    try {
      const config = {
        headers: {},
      };
      this.addTokenToRequest(config);
      const response: AxiosResponse<T> = await axios.post(url, formData, config);
      return response.data;
    } catch (error) {
      throw error;
    }
  }

}

const apiInstance = new Api();

export default apiInstance;
