/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, ChangeEvent } from 'react';
import { CuotasRequest } from '../../../../models/requests';
/**DatePicker */
import Flatpickr from 'react-flatpickr';
import 'flatpickr/dist/l10n/es';
/**Moment */
import moment from 'moment';

interface FormProps {
  onCloseModalForm: () => void;
  formaPago: any;
  handleSelect: (fieldName: string, value: any) => void;
  cuotas: CuotasRequest[];
  onAddResourceItem: (cuotas: CuotasRequest[], formaPago: string) => void;
  fechaEmision: Date | string;
  mtoTotal: number;
}

const FormaPago = (props: FormProps) => {
  //Flatpickr
  const minDate = moment(props.fechaEmision).add(1, 'days').format('YYYY-MM-DD');
  const [formaPago, setFormaPago] = useState<any>(props.formaPago);
  const [cuotas, setCuotas] = useState<CuotasRequest[]>(props.cuotas);


  const handleChangeSelect = (event: ChangeEvent<HTMLSelectElement>) => {
    const selectedFormaPago = event.target.value;
    setFormaPago(selectedFormaPago);
    props.handleSelect('formaPago', selectedFormaPago);
    
  };

  const onAddResourceItem = () => {
    const updatedItems = [...cuotas];
    updatedItems.push({ fechaDePago: '', importe: '' });
    setCuotas(updatedItems);
    props.onAddResourceItem(updatedItems, formaPago);
  };

  const onChange = (index: number, field: string, value: string) => {
    const updatedItems = [...cuotas];
    updatedItems[index][field] = value;
    setCuotas(updatedItems);
    props.onAddResourceItem(updatedItems, formaPago);
  };

  const handleDateChange = (index: number) => (selectedDates: Date[]) => {
    const fecha = moment(selectedDates[0]).format('YYYY-MM-DD');
    const updatedItems = [...cuotas];
    updatedItems[index]['fechaDePago'] = fecha;
    setCuotas(updatedItems);
    props.onAddResourceItem(updatedItems, formaPago);
  };

  const onClickDelete = (index: number) => {
    const updatedItems = [...cuotas];
    updatedItems.splice(index, 1);
    setCuotas(updatedItems);
    props.onAddResourceItem(updatedItems, formaPago);
  };

  return (
    <div className="form form-horizontal form-scrollable">
      <div className="modal-body">
        <div className="form-row row">
          <div className='col-md-12 text-center'>
            <h5>MONTO TOTAL S/ { props.mtoTotal.toFixed(2) }</h5>
          </div>
          <div className="col-md-4">
            <label htmlFor="formaPago" className="form-label">
              Forma de pago
            </label>
            <select
              value={formaPago}
              name="formaPago"
              id="formaPago"
              onChange={handleChangeSelect}
              className="form-control form-control-sm form-select"
            >
              <option value="CONTADO">CONTADO</option>
              <option value="CREDITO">CREDITO</option>
            </select>
          </div>
        </div>
        {formaPago === 'CREDITO' && (
          <div className="form-row row border-sales mt-4">
            <div className="title-items">
              Cuotas{' '}
              <a onClick={() => onAddResourceItem()} className="add-item-sales">
                <i className="ri-add-line"></i>
              </a>
            </div>
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-3">
                  <p className="mb-0">Fecha de pago</p>
                </div>
                <div className="col-md-5">
                  <p className="mb-0">Importe</p>
                </div>
                <div className="col-md-4">
                  <p className="mb-0">Acción</p>
                </div>
              </div>
              {cuotas.map((item: any, index: number) => (
                <div key={index} className="row mt-2">
                  <div className="col-md-3">
                    <Flatpickr
                      value={item.fechaDePago}
                      onChange={handleDateChange(index)}
                      className="form-control form-control-sm"
                      options={{
                        dateFormat: 'Y-m-d',
                        locale: 'es',
                        minDate: minDate,
                      }}
                    />
                  </div>
                  <div className="col-md-5">
                    <input
                      type="number"
                      className="form-control form-control-sm"
                      value={item.importe}
                      onChange={(e) => onChange(index, 'importe', e.target.value)}
                    />
                  </div>
                  <div className="col-md-4">
                    <a onClick={() => onClickDelete(index)} className="text-danger" role="button">
                      <i className="ri-delete-bin-line font-size-18"></i>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
      <div className="modal-footer">
        <button type="button" onClick={() => props.onCloseModalForm()} className="btn btn-sm btn-secondary">
          Cerrar
        </button>
      </div>
    </div>
  );
};

export default FormaPago;
