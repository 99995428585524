/* eslint-disable jsx-a11y/anchor-is-valid */
import { ChangeEvent, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
/**Components */
import ModalComponent from "../../../components/modal";
/** Components Sales */
import HeaderSale from "./headerSale";
import SalesTotals from "./totals";
import SalesHeaderDetail from "./headerDetail";
import SaleBodyDetail from "./bodyDetail";
import FormaPago from "./DatosExtras/FormaPago";
import Anticipos from "./DatosExtras/Anticipos";
import GuiasRemision from "./DatosExtras/GuiasRemision";
import OrdenCompra from "./DatosExtras/OrdenCompra";
import PlacaVehiculo from "./DatosExtras/PlacaVehiculo";
import InvoiceModal from "./InvoiceModal";
import Detracciones from "./Detracciones";
/**Hooks */
import { useSales } from "../../../hooks";
/**Models */
import {
  SaleRequest,
  SaleItemRequest,
  GuidesRequest,
  CuotasRequest,
} from "../../../models/requests";
/**Validations */
import { useFormik } from "formik";
import * as Yup from "yup";
/**Moment */
import moment from "moment";
/**Utilities */
import { SweetAlert } from "../../../utilities";
import { ErrorValidate } from "../../../utilities";
/**Redux */
import { setearIsStateToggle } from "../../../redux/states/loading.slice";

/** Modal Resource */
interface DataModalState {
  size: string;
  type: string;
  buttonSubmit: string | null;
  row: any | null;
  title: string | null;
  requirements: any[];
  onCloseModalForm: () => void;
  errosBackend: any;
}

export const SalesStore = () => {
  const { note, uuid } = useParams();
  const navigate = useNavigate();
  //DATA DEFAULT
  const defaultFormData: SaleRequest = {
    mode: "",
    currency_id: "",
    operation_type_id: 1,
    type_document_id: "",
    client_id: "",
    enviar_correo: false,
    personal_mail: "",
    fechaEmision: moment().format("YYYY-MM-DD"),
    formaPago: "CONTADO",
    cuotas: [],
    anticipos: [],
    guides: [],
    orden_purchase: "",
    placa_vehicle: "",
    items: [],
    totals: {
      descuento: "",
      descuento_global: 0.0,
      descuento_por_item: 0.0,
      descuento_total: 0.0,
      anticipo: 0.0,
      exonerada: 0.0,
      inafecta: 0.0,
      gravada: 0.0,
      igv: 0.0,
      gratuita: 0.0,
      icbper: 0.0,
      total: 0.0,
    },
    observaciones: "",
    detraccion: {
      tipo: "",
      medioPago: "",
      porcentaje: "",
      monto: "",
      itsCta: false,
    },
    type_note_credit_id: "",
    type_note_debit_id: "",
  };
  // //Modal
  const [dataModalResourceState, setDataModalResourceState] =
    useState<DataModalState>({
      size: "",
      type: "",
      buttonSubmit: null,
      row: null,
      title: null,
      requirements: [],
      onCloseModalForm: () => {},
      errosBackend: {},
    });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stateModal, setStateModal] = useState<boolean>(false);
  const [componentModal, setComponentModal] = useState<any>(null);
  const [requirements, setRequirements] = useState<any | null>(null);
  const [loadedComponent, setLoadedComponent] = useState(false);
  const dispatch = useDispatch();
  const [products_not, setProductsNot] = useState<any>([]);
  //Hooks
  const { getRequirements, clientSearch, productSearch, storeSale, getSale } =
    useSales();
  //FormData
  const [formData, setFormData] = useState<SaleRequest>(defaultFormData);
  const validationSchema = Yup.object().shape({});
  const [stateSearclient, setStateSearclient] = useState<boolean>(false);
  const [backendErrors, setBackendErrors] = useState(null);

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: async () => {
      console.log(formData);
      if (formData.type_document_id === "2") {
        if (formData.client_id === "") {
          SweetAlert.warning(
            "Alerta",
            "Para emitir una FACTURA ELECTRÓNICA debe seleccionar un cliente.",
          );
          return;
        }
      } else if (
        formData.type_document_id === "3" &&
        formData.totals.total > 700
      ) {
        if (formData.client_id === "") {
          SweetAlert.warning(
            "Alerta",
            "Para emitir una BOLETA ELECTRÓNICA con montos mayores a 700 debe seleccionar un cliente.",
          );
          return;
        }
      }
      if (formData.enviar_correo && formData.personal_mail === "") {
        SweetAlert.warning(
          "Alerta",
          "Para enviar el correo electrónico debe ingresar un correo válido.",
        );
        return;
      }
      if (formData.items.length === 0) {
        SweetAlert.warning("Alerta", "Debe agregar al menos un item.");
        return;
      }

      storeSale(formData)
        .then((response) => {
          if (response.success) {
            setDataModalResourceState({
              size: "modal-md",
              type: "finish-sale",
              buttonSubmit: null,
              row: formData,
              title: "Venta finalizada",
              requirements: [],
              onCloseModalForm: onCloseModalFinish,
              errosBackend: {},
            });
            setComponentModal(
              <InvoiceModal
                viewFooter={true}
                onCloseModalForm={onCloseModalFinish}
                response={response}
              />,
            );
            setStateModal(true);
            setIsModalOpen(true);
            setFormData(defaultFormData);
            formik.setValues(defaultFormData);
            formik.setFieldValue("selectedClient", null);
            setBackendErrors(null);
            setProductsNot([]);
          } else {
            SweetAlert.error(
              "Error",
              "Ocurrio un error al registrar la venta, revise los errores y vuelva a generar la venta.",
            );
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.status === 422) {
            setBackendErrors(error.response.data.errors);
            SweetAlert.warning(
              "Alerta",
              "Ocurrio un error al registrar la venta, revise los campos faltantes y vuelva a generar la venta.",
            );
          }
        });
    },
  });

  const handleTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    formik.handleChange(event);
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    formik.handleChange(event);
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const handleChangeValue = (name: string, value: any) => {
    setFormData({ ...formData, [name]: value });
  };

  const onClickAddItem = () => {
    const item: SaleItemRequest = {
      product_id: "",
      additional_detail: "",
      quantity: 1,
      igv_type_id: "1",
      unit_value: 0.0,
      sub_total: 0.0,
      total: 0.0,
      view: true,
    };
    setFormData({ ...formData, items: [...formData.items, item] });
  };

  const onClickAddGuide = (guides: GuidesRequest[]) => {
    setFormData({ ...formData, guides: guides });
  };
  const onClickAddCuota = (cuotas: CuotasRequest[], formaPago: string) => {
    setFormData({ ...formData, formaPago: formaPago, cuotas: cuotas });
  };

  const onClickDeleteItem = (index: number) => {
    setProductsNot(
      products_not.filter(
        (item: any) => item !== formData.items[index].product_id,
      ),
    );
    const updatedItems = formData.items.filter((_, i) => i !== index);
    const { cGravada, cIgv, cTotal, montoDetraccion } =
      calculateTotals(updatedItems);
    setFormData({
      ...formData,
      totals: {
        ...formData.totals,
        gravada: cGravada,
        igv: cIgv,
        total: cTotal,
      },
      items: updatedItems,
      detraccion: {
        ...formData.detraccion,
        monto: montoDetraccion.toFixed(2),
      },
    });
  };

  const handleSelectChangeInHeaderSale = (fieldName: string, value: any) => {
    formik.setFieldValue(fieldName, value);
    setFormData({ ...formData, [fieldName]: value });
  };

  const handleSelectDetraccion = (fieldName: string, value: any) => {
    const detraccionFieldName = `detraccion.${fieldName}`;
    formik.setFieldValue(detraccionFieldName, value);

    if (fieldName === "porcentaje") {
      const monto = Math.round(
        (parseFloat(value) * formData.totals.total) / 100,
      );

      if (value !== "") {
        setFormData({
          ...formData,
          detraccion: {
            ...formData.detraccion,
            porcentaje: value,
            monto: monto.toFixed(2),
          },
        });
      } else {
        setFormData({
          ...formData,
          detraccion: { ...formData.detraccion, porcentaje: value, monto: "" },
        });
      }
    } else {
      setFormData({
        ...formData,
        detraccion: { ...formData.detraccion, [fieldName]: value },
      });
    }
  };

  const handleSelectCliente = (clienteId: string, personalMail: any) => {
    formik.setFieldValue("personal_mail", personalMail);
    formik.setFieldValue("client_id", clienteId);
    setFormData({
      ...formData,
      personal_mail: personalMail,
      client_id: clienteId,
    });
  };

  const handleInputChangeInSaleBody = (
    index: number,
    fieldName: string,
    value: string,
  ) => {
    const updatedItems = [...formData.items];
    let igv = 1;
    updatedItems[index][fieldName] = value;
    if (updatedItems[index]["unit_value"] >= 0) {
      let subtotalItem =
        parseFloat(updatedItems[index].quantity.toString()) *
        parseFloat(updatedItems[index].unit_value.toString());
      updatedItems[index].sub_total = subtotalItem;
      switch (updatedItems[index]["igv_type_id"]) {
        case "1":
        case 1: // GRAVADO - OPERACIÓN ONEROSA
          igv = 1.18;
          break;

        default:
          break;
      }
      updatedItems[index].total = updatedItems[index].sub_total * igv;

      const { cInafecta, cGravada, cIgv, cTotal, montoDetraccion } =
        calculateTotals(updatedItems);

      setFormData({
        ...formData,
        totals: {
          ...formData.totals,
          inafecta: cInafecta,
          gravada: cGravada,
          igv: cIgv,
          total: cTotal,
        },
        items: updatedItems,
        detraccion: {
          ...formData.detraccion,
          monto: montoDetraccion.toFixed(2),
        },
      });
    }
  };

  const calculateTotals = (items: any) => {
    let cInafecta = 0;
    let cGravada = 0;
    let cIgv = 0;
    let cTotal = 0;
    let montoDetraccion = 0;
    items.forEach((item: any) => {
      switch (item.igv_type_id) {
        case "1":
        case 1:
          cGravada += item.sub_total;
          cIgv += item.sub_total * 0.18;
          break;
        case "11":
        case 11:
          cInafecta += item.sub_total;
          break;
        default:
          break;
      }
      cTotal += item.total;
    });

    if (formData.operation_type_id === "3") {
      const value = parseFloat(formData.detraccion.porcentaje.toString());
      if (!isNaN(value) && value > 0) {
        const monto = Math.round((value * cTotal) / 100);
        montoDetraccion = monto;
      }
    }

    return { cInafecta, cGravada, cIgv, cTotal, montoDetraccion };
  };

  /**Modal */
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const onCloseModalFinish = () => {
    if (uuid) {
      setStateModal(false);
      setIsModalOpen(false);
      const modalBackdrops = document.getElementsByClassName("modal-backdrop");
      while (modalBackdrops.length > 0) {
        modalBackdrops[0]?.parentNode?.removeChild(modalBackdrops[0]);
      }
      navigate("/sales-history");
    } else {
      setStateModal(false);
    }
  };

  const onCloseModalForm = () => {
    setStateModal(false);
  };

  /**Modal - Datos extras */
  const onClickOpenDatosExtras = (type: string) => {
    let component = null;
    let title = "";
    let size = "";
    const monto: any = formData.detraccion.monto;
    switch (type) {
      case "FormaPago":
        component = (
          <FormaPago
            fechaEmision={formData.fechaEmision}
            onAddResourceItem={onClickAddCuota}
            cuotas={formData.cuotas}
            handleSelect={handleSelectChangeInHeaderSale}
            formaPago={formData.formaPago}
            mtoTotal={formData.totals.total - monto}
            onCloseModalForm={onCloseModalForm}
          />
        );
        title = "Forma de pago";
        size = "modal-lg";
        break;
      case "Anticipos":
        component = <Anticipos />;
        title = "Anticipos";
        size = "modal-lg";
        break;
      case "GuiasRemision":
        component = (
          <GuiasRemision
            guides={formData.guides}
            onAddResourceItem={onClickAddGuide}
            onCloseModalForm={onCloseModalForm}
          />
        );
        title = "Guias de Remision";
        size = "modal-lg";
        break;
      case "OrdenCompra":
        component = (
          <OrdenCompra
            onCloseModalForm={onCloseModalForm}
            formData={formData}
            onChange={handleChangeValue}
          />
        );
        title = "Orden de compra/servicio";
        size = "modal-md";
        break;
      case "PlacaVehiculo":
        component = (
          <PlacaVehiculo
            onCloseModalForm={onCloseModalForm}
            formData={formData}
            onChange={handleChangeValue}
          />
        );
        title = "Placa vehículo";
        size = "modal-md";
        break;
      default:
        break;
    }
    setDataModalResourceState({
      size: size,
      type: "datos-extras",
      buttonSubmit: null,
      row: formData,
      title: title,
      requirements: [],
      onCloseModalForm: onCloseModalForm,
      errosBackend: {},
    });
    setComponentModal(component);
    setStateModal(true);
    setIsModalOpen(true);
  };

  const handleCheckboxChange = () => {
    formik.setFieldValue("enviar_correo", !formik.values.enviar_correo);
    setFormData({ ...formData, enviar_correo: !formData.enviar_correo });
  };

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    formik.handleChange(event);
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  //Data inicial [GET]
  useEffect(() => {
    const dataInicial = () => {
      if (uuid && note && !loadedComponent) {
        getSale(uuid, note).then((res: any) => {
          setFormData(res.sale);
          setProductsNot(res.sale.item_products_id);
          setRequirements(res.requirements);
          formik.setFieldValue("personal_mail", res.sale.personal_mail);
          formik.setFieldValue("currency_id", res.sale.currency_id);
          setStateSearclient(false);
          setLoadedComponent(true);
        });
      } else {
        getRequirements("store").then((response: any) => {
          setProductsNot([]);
          setRequirements(response.requirements);
          setFormData(response.sale);
          setStateSearclient(true);
          setLoadedComponent(true);
        });
      }
    };

    if (!loadedComponent) {
      dataInicial();
    }
  }, [formik, getRequirements, getSale, loadedComponent, note, uuid]);

  useEffect(() => {
    dispatch(setearIsStateToggle("true"));
    return () => {
      dispatch(setearIsStateToggle("false"));
    };
  }, [dispatch]);

  useEffect(() => {
    return () => {
      setLoadedComponent(false)
    };
  }, [uuid]);

  return (
    <div className="main-body p-0">
      <div className="inner-wrapper">
        <div className="inner-main">
          <div className="inner-main-body">
            <div className="card card-featured card-featured-primary mb-3 mt-3">
              <div className="card-body">
                <form className="form" onSubmit={formik.handleSubmit}>
                  <HeaderSale
                    errors={backendErrors}
                    title={note ?? ""}
                    name={formData.document_complete ?? ""}
                    itsCliente={stateSearclient}
                    handleSelectCliente={handleSelectCliente}
                    clientSearch={clientSearch}
                    formik={formik}
                    clientName={formData.client_name ?? ""}
                    handleSelectChange={handleSelectChange}
                    handleSelectChangeInHeaderSale={
                      handleSelectChangeInHeaderSale
                    }
                    getRequirements={requirements}
                  />
                  {/* Detalle de la venta*/}
                  <div className="row border-sales">
                    <div className="title-items">
                      Items{" "}
                      <a
                        onClick={() => onClickAddItem()}
                        className="add-item-sales"
                      >
                        <i className="ri-add-line"></i>
                      </a>
                    </div>
                    <div className="col-md-12">
                      <SalesHeaderDetail />
                    </div>
                    {/* Array items*/}
                    <SaleBodyDetail
                      getRequirements={requirements}
                      productSearch={productSearch}
                      items={formData.items}
                      onClickDeleteItem={onClickDeleteItem}
                      handleInputChange={handleInputChangeInSaleBody}
                      clientId={formData.client_id?.toString() ?? ""}
                      setProductsNot={setProductsNot}
                      products_not={products_not}
                      backendErrors={backendErrors}
                    />
                  </div>
                  <div className="row">
                    {/* Datos extras*/}
                    <div className="col-md-8 mt-3">
                      <div className="row border-sales p-1">
                        <div className="col-md-12 text-center">
                          <button
                            onClick={() => onClickOpenDatosExtras("FormaPago")}
                            type="button"
                            className="btn btn-link text-decoration-none"
                          >
                            Forma de pago
                          </button>
                          {/* <button
                            onClick={() => onClickOpenDatosExtras('Anticipos')}
                            type="button"
                            className="btn btn-link text-decoration-none"
                          >
                            Anticipos
                          </button> */}
                          <button
                            onClick={() =>
                              onClickOpenDatosExtras("GuiasRemision")
                            }
                            type="button"
                            className="btn btn-link text-decoration-none"
                          >
                            Guías de Remisión
                          </button>
                          <button
                            onClick={() =>
                              onClickOpenDatosExtras("OrdenCompra")
                            }
                            type="button"
                            className="btn btn-link text-decoration-none"
                          >
                            Órden compra/servicio
                          </button>
                          <button
                            onClick={() =>
                              onClickOpenDatosExtras("PlacaVehiculo")
                            }
                            type="button"
                            className="btn btn-link text-decoration-none"
                          >
                            Placa vehículo
                          </button>
                        </div>
                      </div>
                      <div className="row border-sales">
                        <div className="col-md-12">
                          <label htmlFor="observaciones">Observaciones</label>
                          <textarea
                            value={formData.observaciones}
                            onChange={handleTextAreaChange}
                            rows={3}
                            className="form-control todo-mayuscula"
                            name="observaciones"
                            id="observaciones"
                          ></textarea>
                        </div>
                      </div>
                      {(formData.operation_type_id === "3") && (
                        <Detracciones
                          itsCta={formData.detraccion.itsCta}
                          handleSelectDetraccion={handleSelectDetraccion}
                          formik={formik}
                          detraccion={formData.detraccion}
                          requirements={requirements}
                        />
                      )}
                      <div className="row border-sales mt-3">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-2">
                              <label htmlFor="square-switch1">
                                ¿Enviar correo?
                              </label>
                              <div className="square-switch">
                                <input
                                  onChange={handleCheckboxChange}
                                  type="checkbox"
                                  id="square-switch1"
                                  checked={formData.enviar_correo}
                                />
                                <label
                                  htmlFor="square-switch1"
                                  data-on-label="Si"
                                  data-off-label="No"
                                ></label>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <label htmlFor="personal_mail">
                                Correo electrónico
                              </label>
                              <input
                                autoComplete="off"
                                onChange={handleInputChange}
                                name="personal_mail"
                                id="personal_mail"
                                value={formik.values.personal_mail ?? ""}
                                type="text"
                                className={
                                  "form-control form-control-sm" +
                                  (formik.errors.personal_mail &&
                                  formik.touched.personal_mail
                                    ? " is-invalid"
                                    : "")
                                }
                              />
                              <ErrorValidate
                                state={formik.errors.personal_mail}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Totales*/}
                    <SalesTotals totals={formData.totals} />

                    {/* Botones*/}
                    <div className="row d-flex justify-content-center mt-3">
                      <div className="d-grid col-md-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg btn-block"
                        >
                          Generar comprobante
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ModalComponent
          stateModal={stateModal}
          typeModal={"static"}
          size={dataModalResourceState.size}
          title={dataModalResourceState.title}
          content={componentModal}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default SalesStore;
