/* eslint-disable jsx-a11y/anchor-is-valid */
import { ChangeEvent, useState, useEffect } from "react";
import { useDispatch } from "react-redux";
/**Moment */
import moment from "moment";
/**Utilities */
import { SweetAlert } from "../../../utilities";
/**Models */
import { QuoteRequest, SaleItemRequest } from "../../../models/requests";
/**Validations */
import { useFormik } from "formik";
import * as Yup from "yup";
/**Hooks */
import { useSales, useQuotes } from "../../../hooks";
/** Components Sales */
import SalesHeaderDetail from "../../Sales/Store/headerDetail";
import SaleBodyDetail from "../../Sales/Store/bodyDetail";
import SalesTotals from "../../Sales/Store/totals";
/** Components Quotes */
import HeaderQuote from "./HeaderQuote";
import ModalComponent from "../../../components/modal";
import FinishQuote from "./FinishQuote";
/**Hooks */
import { setearIsStateToggle } from "../../../redux/states/loading.slice";
import SearchProduct from "./SearchProduct";

/** Modal Resource */
interface DataModalState {
  size: string;
  type: string;
  buttonSubmit: string | null;
  row: any | null;
  title: string | null;
  requirements: any[];
  onCloseModalForm: () => void;
  errosBackend: any;
}

const QuotesStore = () => {
  //DATA DEFAULT
  const defaultFormData: QuoteRequest = {
    operation_type_id: 1,
    currency_id: "PEN",
    client_id: "",
    fechaVencimiento: moment().format("YYYY-MM-DD"),
    formaPago: "CONTADO",
    items: [],
    totals: {
      descuento: "",
      descuento_global: 0.0,
      descuento_por_item: 0.0,
      descuento_total: 0.0,
      anticipo: 0.0,
      exonerada: 0.0,
      inafecta: 0.0,
      gravada: 0.0,
      igv: 0.0,
      gratuita: 0.0,
      icbper: 0.0,
      total: 0.0,
    },
    observaciones: "",
  };
  //Modal
  const [dataModalResourceState, setDataModalResourceState] =
    useState<DataModalState>({
      size: "",
      type: "",
      buttonSubmit: null,
      row: null,
      title: null,
      requirements: [],
      onCloseModalForm: () => {},
      errosBackend: {},
    });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [stateModal, setStateModal] = useState<boolean>(false);
  const [componentModal, setComponentModal] = useState<any>(null);
  /** Modal Window Resource */
  const [isModalWindowOpen, setIsModalWindowOpen] = useState(false);
  const [dataModalWindowResourceState, setDataModalWindowResourceState] =
    useState({
      title: "",
      size: "",
    });
  const [stateModalWindow, setStateModalWindow] = useState<boolean>(false);
  //FormData
  const [formData, setFormData] = useState<QuoteRequest>(defaultFormData);
  const validationSchema = Yup.object().shape({});
  //Hooks
  const { getRequirements, clientSearch, productSearch } = useSales();
  const { storeQuote } = useQuotes();
  const dispatch = useDispatch();
  const [requirements, setRequirements] = useState<any | null>(null);
  const [loadedComponent, setLoadedComponent] = useState(false);
  const [products_not, setProductsNot] = useState<any>([]);
  const [backendErrors, setBackendErrors] = useState(null);
  const [products, setProducts] = useState([]);
  const [textSearchProduct, setTextSearchProduct] = useState("");

  useEffect(() => {
    dispatch(setearIsStateToggle("true"));
    return () => {
      dispatch(setearIsStateToggle("false"));
    };
  }, [dispatch]);

  const formik = useFormik({
    initialValues: formData,
    validationSchema,
    onSubmit: async () => {
      console.log(formData);
      if (formData.client_id === "") {
        SweetAlert.warning(
          "Alerta",
          "Para realizar una COTIZACIÓN debes seleccionar un cliente.",
        );
        return;
      }
      if (formData.items.length === 0) {
        SweetAlert.warning("Alerta", "Debe agregar al menos un item.");
        return;
      }

      //STORE
      storeQuote(formData)
        .then((response) => {
          if (response.success) {
            setDataModalResourceState({
              size: "modal-md",
              type: "finish-quote",
              buttonSubmit: null,
              row: formData,
              title: "Cotización finalizada",
              requirements: [],
              onCloseModalForm: onCloseModalForm,
              errosBackend: {},
            });
            setComponentModal(<FinishQuote response={response} />);
            setStateModal(true);
            setIsModalOpen(true);
            setFormData(defaultFormData);
            formik.setValues(defaultFormData);
            formik.setFieldValue("selectedClient", null);
            setBackendErrors(null);
            setProductsNot([]);
          } else {
            console.log(response);
            SweetAlert.error(
              "Error",
              "Ocurrio un error al registrar la cotización, revise los errores y vuelva a generar la cotización.",
            );
          }
        })
        .catch((error) => {
          console.log(error);
          setBackendErrors(error.response.data.errors);
          SweetAlert.error(
            "Error",
            "Ocurrio un error al registrar la cotización, revise los errores y vuelva a generar la cotización.",
          );
        });
    },
  });

  const handleSelectChange = (event: ChangeEvent<HTMLSelectElement>) => {
    formik.handleChange(event);
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const handleSelectChangeInHeaderSale = (fieldName: string, value: any) => {
    formik.setFieldValue(fieldName, value);
    setFormData({ ...formData, [fieldName]: value });
  };

  const onClickAddItem = () => {
    const item: SaleItemRequest = {
      product_id: "",
      additional_detail: "",
      quantity: 1,
      igv_type_id: 1,
      unit_value: 0.0,
      sub_total: 0.0,
      total: 0.0,
      view: true,
    };
    setFormData({ ...formData, items: [...formData.items, item] });
  };

  const onKeyUpSearchProduct = (e: any) => {
    let productsNot = products_not.join(",");
    productSearch(e.target.value, "", productsNot).then((res: any) => {
      setProducts(res.data);
    });
  };

  const onAddProductSearch = (product: any) => {
    const item: SaleItemRequest = {
      product_id: product.value,
      product_name: product.label,
      additional_detail: "",
      quantity: 1,
      igv_type_id: 1,
      unit_value: product.unit_value,
      sub_total: product.unit_value,
      total: product.precio_unitario * 1,
      view: false,
      selectedProduct: product,
    };
    const updatedItems = formData.items;
    updatedItems.push(item);
    const { cGravada, cIgv, cTotal } = calculateTotals(updatedItems);
    setFormData({
      ...formData,
      totals: {
        ...formData.totals,
        gravada: cGravada,
        igv: cIgv,
        total: cTotal,
      },
      items: updatedItems,
    });
    setProducts([]);
    setProductsNot(products_not.concat(product.value));
    setTextSearchProduct("");
  };

  const onSearchItem = () => {
    setDataModalWindowResourceState({
      size: "modal-gg-plus",
      title: "Buscador de servicios y productos",
    });
    setStateModalWindow(true);
    setIsModalWindowOpen(true);
  };

  const onClickDeleteItem = (index: number) => {
    setProductsNot(
      products_not.filter(
        (item: any) => item !== formData.items[index].product_id,
      ),
    );
    const updatedItems = formData.items.filter((_, i) => i !== index);
    const { cGravada, cIgv, cTotal } = calculateTotals(updatedItems);
    setFormData({
      ...formData,
      totals: {
        ...formData.totals,
        gravada: cGravada,
        igv: cIgv,
        total: cTotal,
      },
      items: updatedItems,
    });
  };

  const handleInputChangeInSaleBody = (
    index: number,
    fieldName: string,
    value: string,
  ) => {
    const updatedItems = [...formData.items];
    let igv = 1;
    updatedItems[index][fieldName] = value;
    if (updatedItems[index]["unit_value"] >= 0) {
      let subtotalItem =
        parseFloat(updatedItems[index].quantity.toString()) *
        parseFloat(updatedItems[index].unit_value.toString());
      updatedItems[index].sub_total = subtotalItem;
      switch (updatedItems[index]["igv_type_id"]) {
        case 1: // GRAVADO - OPERACIÓN ONEROSA
          igv = 1.18;
          break;

        default:
          break;
      }
      updatedItems[index].total = updatedItems[index].sub_total * igv;

      const { cGravada, cIgv, cTotal } = calculateTotals(updatedItems);

      setFormData({
        ...formData,
        totals: {
          ...formData.totals,
          gravada: cGravada,
          igv: cIgv,
          total: cTotal,
        },
        items: updatedItems,
      });
    }
  };

  const calculateTotals = (items: any) => {
    let cGravada = 0;
    let cIgv = 0;
    let cTotal = 0;
    items.forEach((item: any) => {
      if (item.igv_type_id === 1) {
        cGravada += item.sub_total;
        cIgv += item.sub_total * 0.18;
      }
      cTotal += item.total;
    });

    return { cGravada, cIgv, cTotal };
  };

  /**Modal */
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const onCloseModalForm = () => {
    setStateModal(false);
  };

  const handleTextAreaChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    formik.handleChange(event);
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  /** METODOS DEL MODAL WINDOW */
  const handleCloseModalWindow = () => {
    setTextSearchProduct("");
    setProducts([]);
    setIsModalWindowOpen(false);
  };

  //Data inicial [GET]
  useEffect(() => {
    const dataInicial = () => {
      getRequirements("").then((response) => {
        setRequirements(response);
        setLoadedComponent(true);
      });
    };

    if (!loadedComponent) {
      dataInicial();
    }
  }, [getRequirements, loadedComponent]);

  return (
    <div className="main-body p-0">
      <div className="inner-wrapper">
        <div className="inner-main">
          <div className="inner-main-body">
            <div className="card card-featured card-featured-primary mb-3 mt-3">
              <div className="card-body">
                <form className="form" onSubmit={formik.handleSubmit}>
                  <HeaderQuote
                    clientSearch={clientSearch}
                    formik={formik}
                    handleSelectChange={handleSelectChange}
                    handleSelectChangeInHeaderSale={
                      handleSelectChangeInHeaderSale
                    }
                  />
                  {/* Detalle de la venta*/}
                  <div className="row border-sales">
                    <div className="title-items">
                      Items{" "}
                      {process.env.REACT_APP_SEARCH_ADD_PRODUCT === "TRUE" && (
                        <a
                          onClick={() => onClickAddItem()}
                          className="add-item-sales"
                        >
                          <i className="ri-add-line"></i>
                        </a>
                      )}
                      <a
                        onClick={() => onSearchItem()}
                        role="button"
                        className="add-item-sales ms-2"
                      >
                        <i className="fa-solid fa-magnifying-glass"></i>
                      </a>
                    </div>
                    <div className="col-md-12">
                      <SalesHeaderDetail />
                    </div>
                    {/* Array items*/}
                    <SaleBodyDetail
                      getRequirements={requirements}
                      productSearch={productSearch}
                      items={formData.items}
                      onClickDeleteItem={onClickDeleteItem}
                      handleInputChange={handleInputChangeInSaleBody}
                      clientId={formData.client_id?.toString() ?? ""}
                      setProductsNot={setProductsNot}
                      products_not={products_not}
                      backendErrors={backendErrors}
                    />
                  </div>
                  <div className="row">
                    {/* Datos extras*/}
                    <div className="col-md-8 mt-3">
                      <div className="row border-sales">
                        <div className="col-md-12">
                          <label htmlFor="observaciones">Observaciones</label>
                          <textarea
                            value={formData.observaciones}
                            onChange={handleTextAreaChange}
                            rows={4}
                            className="form-control todo-mayuscula"
                            name="observaciones"
                            id="observaciones"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    {/* Totales*/}
                    <SalesTotals totals={formData.totals} />

                    {/* Botones*/}
                    <div className="row d-flex justify-content-center mt-3">
                      <div className="d-grid col-md-4">
                        <button
                          type="submit"
                          className="btn btn-primary btn-lg btn-block"
                        >
                          Generar
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ModalWindow Resource */}
      {isModalWindowOpen && (
        <ModalComponent
          stateModal={stateModalWindow}
          typeModal={"true"}
          size={dataModalWindowResourceState.size}
          title={dataModalWindowResourceState.title}
          content={
            <SearchProduct
              onCloseModalForm={onCloseModalForm}
              productSearch={productSearch}
              onAddProductSearch={onAddProductSearch}
              onKeyUpSearchProduct={onKeyUpSearchProduct}
              products={products}
              textSearchProduct={textSearchProduct}
              setTextSearchProduct={setTextSearchProduct}
            />
          }
          onClose={handleCloseModalWindow}
        />
      )}
      {isModalOpen && (
        <ModalComponent
          stateModal={stateModal}
          typeModal={"true"}
          size={dataModalResourceState.size}
          title={dataModalResourceState.title}
          content={componentModal}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default QuotesStore;
