import { useState, useEffect } from "react";
/**Components */
import HeaderPage from "../../../components/page/header";
import BodyPage from "../../../components/page/body";
import ModalComponent from "../../../components/modal";
/**Hooks */
import { useSales } from "../../../hooks";
/** Componentes propios */
import Filter from "./Filter";
import InfoSunatModal from "./InfoSunatModal";
import EnviarCorreo from "./EnviarCorreo";
import Cancel from "./Cancel";
import { CancelRequest } from "../../../models/requests";
/**Utilities */
import { SweetAlert } from "../../../utilities";
import { useNavigate } from "react-router-dom";

/** Modal Resource */
interface DataModalState {
  size: string;
  type: string;
  buttonSubmit: string | null;
  row: any | null;
  title: string | null;
  requirements: any[];
  onCloseModalForm: () => void;
  errosBackend: any;
}

export const SalesHistory = () => {
  const navigate = useNavigate();
  const { fetchSales, getRequirements, cancelSale, sendEmail } = useSales();
  const [filterState, setFilterState] = useState({
    page: 1,
    text: "",
    typeDocument: "",
    dateInitial: "",
    dateFinal: "",
    selectedDates: [],
  });
  //Modal
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dataModalResourceState, setDataModalResourceState] =
    useState<DataModalState>({
      size: "",
      type: "",
      buttonSubmit: null,
      row: null,
      title: null,
      requirements: [],
      onCloseModalForm: () => {},
      errosBackend: {},
    });
  const [stateModal, setStateModal] = useState<boolean>(false);
  const [componentModal, setComponentModal] = useState<any>(null);
  const [requirements, setRequirements] = useState<any | null>(null);
  const [loadedComponent, setLoadedComponent] = useState(false);

  //DATA TABLE
  const state = {
    page: {
      title: "Historial de ventas",
      icon: "ri-store-line",
      model: "sales",
      header: {
        menu: ["Ventas", "Historial"],
      },
      buttons: {
        create: false,
        edit: false,
        destroy: false,
        import: false,
        export: false,
      },
    },
    dataTable: {
      body: {
        widthAccion: "160px",
        cols: [
          {
            name: "client_document_number",
            alias: "Número documento",
            width: "170px",
          },
          {
            name: "client_names",
            alias: "Cliente",
          },
          {
            name: "fecha_emision",
            alias: "Fecha emisión",
            width: "120px",
          },
          {
            name: "type_document_abbreviation",
            alias: "Documento",
            width: "110px",
          },
          {
            name: "document_complete",
            alias: "Número",
            width: "90px",
          },
          {
            name: "total",
            alias: "Total",
            width: "90px",
          },
          {
            name: "state",
            alias: "Estado",
            width: "90px",
            play: {
              type: "states",
              values: {
                0: "bg-danger-subtle text-danger",
                1: "bg-success-subtle text-success",
                2: "bg-info-subtle text-info",
                3: "bg-warning-subtle text-warning",
              },
              names: {
                0: "Anulado",
                1: "Aceptado",
                2: "Rechazado",
                3: "Procesando",
              },
            },
          },
          {
            name: "send_email",
            alias: "Correo",
            width: "90px",
            play: {
              type: "states",
              values: {
                0: "bg-danger-subtle text-danger",
                1: "bg-success-subtle text-success",
              },
              names: {
                0: "No enviado",
                1: "Enviado",
              },
            },
          },
        ],
        buttons: [
          {
            name: "email",
            tooltip: "Enviar correo",
            text: "",
            css: "me-3 text-info",
            icon: "fa-regular fa-paper-plane",
            play: {
              type: "alls",
            },
          },
          {
            name: "print",
            tooltip: "Ver PDF",
            text: "",
            css: "me-3 text-danger",
            icon: "far fa-file-pdf",
            play: {
              type: "alls",
            },
          },
          {
            name: "xml",
            tooltip: "Ver XML",
            text: "",
            css: "me-3 text-warning",
            icon: "fa-regular fa-file-lines",
            play: {
              type: "states",
              name: "type_document_id",
              values: {
                "1": false,
                "2": true,
                "3": true,
                "4": true,
                "5": true,
              },
            },
          },
          {
            name: "options",
            tooltip: "Opciones",
            text: "",
            css: "btn-link",
            icon: "fa-solid fa-bars",
            play: {
              type: "dropdowns",
              items: [
                {
                  name: "credit_note",
                  text: "Generar Nota de Crédito",
                  key: "documento_anulado",
                  mode: "states",
                  values: {
                    0: true,
                    1: false,
                  },
                },
                {
                  name: "debit_note",
                  text: "Generar Nota de Débito",
                  key: "documento_anulado",
                  mode: "states",
                  values: {
                    0: true,
                    1: false,
                  },
                },
                {
                  name: "cancel",
                  text: "Anular venta",
                  key: "state_anulado",
                  mode: "states",
                  values: {
                    0: false,
                    1: true,
                  },
                },
                {
                  name: "info",
                  text: "Ver información de SUNAT",
                  key: "type_document_id",
                  mode: "states",
                  values: {
                    "1": false,
                    "2": true,
                    "3": true,
                    "4": true,
                    "5": true,
                  },
                },
              ],
            },
          },
        ],
      },
    },
  };

  const onClickAddResource = () => {};
  const onClickEditResource = () => {};
  const onClickDeleteResource = () => {};
  //Data inicial [GET]
  useEffect(() => {
    const dataInicial = () => {
      getRequirements("").then((response) => {
        setRequirements(response);
        setLoadedComponent(true);
      });
    };

    if (!loadedComponent) {
      dataInicial();
    }
  }, [getRequirements, loadedComponent]);
  useEffect(() => {
    fetchSales(1, "", "", "", "", true, "sales");
  }, [fetchSales]);
  /**Modal */
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };
  const onCloseModalForm = () => {
    setStateModal(false);
  };
  const onClickButtonPersonalizado = (row: any, name: any) => {
    switch (name) {
      case "email":
        onClickEmail(row);
        break;
      case "print":
        onClickPrint(row);
        break;
      case "xml":
        onClickXml(row);
        break;
      case "info":
        onClickInfoSunat(row);
        break;
      case "cancel":
        onClickCancel(row);
        break;
      case "credit_note":
        onClickCreditNote(row);
        break;
      case "debit_note":
        onClickDebitNote(row);
        break;
      default:
        break;
    }
  };

  const onSubmitFormCancel = (saleId: string, cancel: CancelRequest) => {
    cancelSale(saleId, cancel)
      .then((response) => {
        if (response.state) {
          SweetAlert.success("Mensaje", "Venta anulada correctamente.");
        } else {
          SweetAlert.error("Mensaje", "Error al anular la venta.");
        }
        onCloseModalForm();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onSubmitFormEmail = (saleId: string, email: string) => {
    sendEmail(saleId, email)
      .then((response) => {
        if (response.state) {
          SweetAlert.success("Mensaje", response.message);
        } else {
          SweetAlert.error("Mensaje", "Error al enviar el correo.");
        }
        onCloseModalForm();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onClickPrint = (row: any) => {
    window.open(
      `${process.env.REACT_APP_LINK_BACKEND}/cpe/a4/${row.uuid}.pdf`,
      "_blank",
    );
  };
  const onClickXml = (row: any) => {
    window.open(`${process.env.REACT_APP_LINK_FACTURALO}/cpe/${row.uuid}.xml`, "_blank");
  };
  const onClickEmail = (row: any) => {
    setDataModalResourceState({
      size: "modal-md",
      type: "",
      buttonSubmit: null,
      row: row,
      title: "Enviar correo",
      requirements: [],
      onCloseModalForm: onCloseModalForm,
      errosBackend: {},
    });
    setComponentModal(
      <EnviarCorreo
        onSubmitFormEmail={onSubmitFormEmail}
        onCloseModalForm={onCloseModalForm}
        data={row}
      />,
    );
    setStateModal(true);
    setIsModalOpen(true);
  };
  const onClickInfoSunat = (row: any) => {
    setDataModalResourceState({
      size: "modal-md",
      type: "",
      buttonSubmit: null,
      row: row,
      title: "Ver información de SUNAT",
      requirements: [],
      onCloseModalForm: onCloseModalForm,
      errosBackend: {},
    });
    setComponentModal(
      <InfoSunatModal onCloseModalForm={onCloseModalForm} data={row} />,
    );
    setStateModal(true);
    setIsModalOpen(true);
  };
  const onClickCancel = (row: any) => {
    setDataModalResourceState({
      size: "modal-md",
      type: "",
      buttonSubmit: null,
      row: row,
      title: "Anular venta " + row.serie + "-" + row.numeracion,
      requirements: [],
      onCloseModalForm: onCloseModalForm,
      errosBackend: {},
    });
    setComponentModal(
      <Cancel
        onSubmitFormCancel={onSubmitFormCancel}
        onCloseModalForm={onCloseModalForm}
        data={row}
      />,
    );
    setStateModal(true);
    setIsModalOpen(true);
  };
  const onClickCreditNote = (row: any) => {
    navigate("/sales-note/credit/" + row.uuid);
  };
  const onClickDebitNote = (row: any) => {
    navigate("/sales-note/debit/" + row.uuid);
  };

  /** METODOS DEL FILTER */
  const onChangePage = (page: number, type: string) => {
    switch (type) {
      case "prev":
        setFilterState({ ...filterState, page: filterState.page - 1 });
        fetchSales(
          filterState.page - 1,
          filterState.text,
          filterState.typeDocument,
          filterState.dateInitial,
          filterState.dateFinal,
          true,
          "sales",
        );
        break;

      case "current":
        setFilterState({ ...filterState, page: page });
        fetchSales(
          page,
          filterState.text,
          filterState.typeDocument,
          filterState.dateInitial,
          filterState.dateFinal,
          true,
          "sales",
        );
        break;
      case "next":
        setFilterState({ ...filterState, page: filterState.page + 1 });
        fetchSales(
          filterState.page + 1,
          filterState.text,
          filterState.typeDocument,
          filterState.dateInitial,
          filterState.dateFinal,
          true,
          "sales",
        );
        break;
      default:
        break;
    }
  };
  const onClearFilters = () => {
    setFilterState({
      ...filterState,
      page: 1,
      text: "",
      typeDocument: "",
      dateInitial: "",
      dateFinal: "",
      selectedDates: [],
    });
    fetchSales(1, "", "", "", "", true, "sales");
  };

  const handleFilterChange = (newFilters: any, loading: boolean) => {
    setFilterState(newFilters);
    fetchSales(
      1,
      newFilters.text,
      newFilters.typeDocument,
      newFilters.dateInitial,
      newFilters.dateFinal,
      loading,
      "sales",
    );
  };

  return (
    <div className="main-body p-0">
      <div className="inner-wrapper">
        <div className="inner-main">
          <HeaderPage page={state.page} onModalResource={onClickAddResource} />
          <div className="inner-main-body">
            <div className="card card-featured card-featured-primary mb-3 mt-3">
              <div className="card-body">
                <Filter
                  requirements={requirements}
                  onClearFilters={onClearFilters}
                  filterState={filterState}
                  onFilterChange={handleFilterChange}
                />
                <BodyPage
                  onChangeDelete={onClickDeleteResource}
                  onClickButtonPersonalizado={onClickButtonPersonalizado}
                  onChangeEdit={onClickEditResource}
                  onChangePage={onChangePage}
                  page={state.page}
                  table={state.dataTable}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <ModalComponent
          stateModal={stateModal}
          typeModal={"true"}
          size={dataModalResourceState.size}
          title={dataModalResourceState.title}
          content={componentModal}
          onClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default SalesHistory;
