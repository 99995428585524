

interface SearchProductProps {
  onCloseModalForm: any;
  productSearch: any;
  onAddProductSearch: any;
  onKeyUpSearchProduct: any;
  products: any;
  textSearchProduct: string;
  setTextSearchProduct: any;
}

export const SearchProduct = (props: SearchProductProps) => {
  
  return (
    <div className="modal-body">
      <div className="form-row row">
        <div className="col-md-12 form-group">
          <label
            className="col-form-label label-modal"
            htmlFor="document_number"
          >
            Servicio o producto
          </label>
          <input
            type="search"
            className="todo-mayuscula form-control form-control-sm"
            placeholder="Buscar"
            onKeyUp={props.onKeyUpSearchProduct}
            value={props.textSearchProduct}
            onChange={(e) => props.setTextSearchProduct(e.target.value)}
          />
        </div>
        <div className="col-md-12 form-group">
          <h6 className="mt-4 mb-4">Resultados de la búsqueda:</h6>
          <table className="table table-bordered table-sm mb-0 font-inter table-zh">
            <thead className="thead-primary">
              <tr>
                <th>Nombre</th>
                <th>Presentación</th>
                <th>Marca</th>
                <th>Precio venta</th>
                <th>Acción</th>
              </tr>
            </thead>
            <tbody>
              {props.products.map((product: any, index: number) => (
                <tr key={index}>
                  <td>{product.only_name}</td>
                  <td>{product.presentation_name}</td>
                  <td>{product.brand_name}</td>
                  <td>{product.precio_unitario}</td>
                  <td>
                    <button
                      type="button"
                      className="btn btn-xs btn-primary"
                      onClick={() => props.onAddProductSearch(product)}
                    >
                      Seleccionar
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default SearchProduct;
